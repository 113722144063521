import React, { useState } from 'react';
import Modal from '../common/Modal';
import SmallScreenFilters from './SmallScreenFilters';


const SubOptions = (props) => {
    const { data, filters, setFilters } = props;
    if (!data) {
        return null;
    }


    const handleOptionClick = (option) => {
        const pos = filters.findIndex(item => item.slug == option.slug);
        if (pos < 0) {
            filters.push(Object.assign(option));
            setFilters(filters.filter(item => true));
        } else {
            const [el] = filters.splice(pos,1);
            let nFilter = filters.filter(item => item.parentId !== el.id);
            if(el.parentId && el.filterId === 'domain'){
                const elem = filters.find(item=>item.id === el.parentId);
                if(elem){
                    nFilter = filters.filter(item => !elem.options.some(so => so.parentId == item.id))
                }
            }
            setFilters(nFilter);
        }
    }

    return (
        <ul className="pw-wt-filter-sub-option-list">
            {data.options.map((option, index) => {
                const optionName = `filter-sub-option-${data.optionIndex}-${index}`;
                return (
                    <li key={optionName}>
                        <div className="pw-wt-sm-filter-sub-option" onClick={() => handleOptionClick(option)}>
                            <div className="pw-wt-sm-fo-label">
                                {option.name}
                                {option.description && <div className="pw-wt-sm-fo-sub-label">
                                    {option.description}
                                </div>}
                            </div>
                            {filters.some(item => item.slug == option.slug) && <div className="pw-wt-sm-fo-tick-mark" />}
                        </div>
                    </li>
                );
            })}
        </ul>
    );
};



const SmallScreenFilterModal = (props) => {
    const { smFilterCls, setSmFilterCls, filterOptions, filters, setFilters, clearFilter, type } = props;
    const [subOptionData, setSubOptionData] = useState(null);

    const clearSubOption = () => {
        const nFilter = filters.filter(item => !subOptionData.options.some(so => so.id == item.id));
        setFilters(nFilter);
    }

    const handleClose = ()=>{
        document.documentElement.classList.remove("pw-no-scroll");
        setSmFilterCls('w3-hide');
    }

    return (
        <Modal openCls={smFilterCls}
            modalStyle={{ paddingTop: 150, backgroundColor: 'transparent' }}
            customStyle={{ margin: 0, borderRadius: 12 }}
            customClass="pw-wt-filter-modal"
            hideOverLay
        >
            <div className="w3-display-container">
                {!subOptionData && <div
                    className="pw-wt-filter-left-btn pw-wt-filter-close"
                    onClick={() => handleClose()}
                />}
                {subOptionData && <div
                    className="pw-wt-filter-left-btn pw-wt-filter-back"
                    onClick={() => setSubOptionData(null)}
                />}
                {subOptionData && filters.some(item => item.parentId === subOptionData.id) && 
                    <div className="pw-wt-filter-suboption-clear" onClick={() => clearSubOption()}>
                    Clear
                </div>}

                 {subOptionData && filters.some(item => item.filterId == subOptionData.slug) &&
                    <div className="pw-wt-filter-suboption-clear" onClick={() => clearSubOption()}>
                        Clear
                </div>
                
                }
            </div>
            <div className="pw-wt-filter-container">
                {!subOptionData && <>
                    {filters.length > 0 && <div className="pw-wt-filter-clear-all" onClick={() => clearFilter()}>
                        Clear All
                            </div>}
                    <div className="w3-row w3-center">
                        <div className="pw-wt-filter-title">
                            Filters
                        </div>
                        <div className="pw-wt-filter-info w3-hide">
                            Save your favourite WiseTips
                            </div>
                        <div className="w3-row w3-hide">
                            <div className="pw-wt-filter-signup">
                                <div className="w3-round-xxlarge pw-wt-filter-reg-btn">
                                    <div className="pw-wt-filter-reg-text">
                                        Sign me up! <span className="pw-wt-filter-reg-btn-arrow" />
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="w3-row">
                        <SmallScreenFilters 
                            filterOptions={filterOptions}
                            setSubOptionData={setSubOptionData} 
                            filters={filters}
                        />
                    </div>
                </>}
                {subOptionData &&
                    <div className="w3-row pw-wt-filter-suboption-container">

                        <div className="w3-center pw-wt-filter-title-section">
                            <div className="pw-wt-filter-main-title">
                                {subOptionData.header}
                            </div>
                            {subOptionData.subHeader && <div className="pw-wt-filter-sub-title">
                                {subOptionData.subHeader}
                            </div>}
                        </div>
                        <SubOptions
                            filterOptions={filterOptions}
                            data={subOptionData}
                            filters={filters}
                            setFilters={setFilters}
                        />
                    </div>}
                <div className="w3-row">
                    <div className="pw-wt-filter-view-wise-tip">
                        <div className="w3-round-xxlarge pw-wt-filter-view-btn" onClick={() => {
                            setSmFilterCls('w3-hide')
                            setSubOptionData(null)
                        }}>
                            <div className="pw-wt-filter-view-btn-text">
                                View {`${(type === 'coreFinding') ? 'CoreFindings': 'WiseTips'}`} <span className="pw-wt-filter-view-btn-arrow" />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </Modal>
    )
}

export default SmallScreenFilterModal;