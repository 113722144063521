import React from 'react';
import RadioButton from '../common/RadioButton';


const BigScreenFilters = (props) => {
    const { filters, setFilters, filterOptions } = props;

    const handleOptionClick = (option) => {
        const pos = filters.findIndex(item => item.slug === option.slug);
        if (pos < 0) {
            const mainCategory = filterOptions.find(fItem => fItem.slug === option.filterId);
            const parent = mainCategory.options.find(item => item.id == option.parentId);

            if (!option.parentId && option.filterId === 'domain') {
                if (!option.parentId && option.options && option.options.length) {
                    option.options.forEach(optionItem => {
                        if (!filters.find(fItem => fItem.slug === optionItem.slug)) {
                            filters.push(optionItem);
                        }
                    });
                }
            }
            filters.push(Object.assign(option));
            if (parent && parent.options && parent.options.length
                && parent.options.every(eItem => filters.find(fItem => eItem.slug === fItem.slug))) {
                if (!filters.find(fItem => fItem.slug === parent.slug)) {
                    filters.push(Object.assign(parent));
                }
            }
            setFilters(filters.filter(item => true));
        } else {
            const [el] = filters.splice(pos,1);
            let nFilter = filters.filter(item => item.parentId !== el.id);
            if(el.parentId && el.filterId === 'domain'){
                const elem = filters.find(item=>item.id === el.parentId);
                if(elem){
                    nFilter = filters.filter(item => !elem.options.some(so => so.parentId == item.id))
                }
            }
            setFilters(nFilter);
        } 
    }

    return (
        <div className="w3-row pw-wt-big-filter">
            {
                filterOptions.map(item => (
                    <div key={item.name}>
                        <div className="pw-filter-category">
                            {item.name}
                        </div>
                        {item.options && <div>
                            {item.options.filter(item => item.parentId === null).map(option => {

                                const children = item.options.filter(row => option.id == row.parentId ).map(subOption => {
                                            return <RadioButton
                                                key={subOption.slug}
                                                subLabel={subOption.name}
                                                value={subOption.slug}
                                                checked={filters.some( option => option.slug === subOption.slug)}
                                                onChange={(val) => handleOptionClick(subOption)}
                                            />
                                        });
                                return <div key={`${option.slug}-${option.name}`}>
                                    <RadioButton
                                        key={option.slug}
                                        label={option.name}
                                        subLabel={option.description}
                                        value={option.slug}
                                        checked={filters.some( row => row.slug === option.slug)}
                                        onChange={(val) => handleOptionClick(option)}
                                    />

                                    {children.length > 0 && <div>{children}</div>}
                                </div>
                            })}
                        </div>}

                    </div>
                ))
            }

        </div>
    )

}

export default BigScreenFilters;