import React, { useState, useEffect } from 'react';
import querystring from 'querystring';
import { navigate } from 'gatsby';
import './wise-tips.scss';
import WiseTipCard from '../common/WiseTipCard';
import closeIcon from '../../images/icons/close.png';

import DidYouKnowModal from '../../components/common/DidYouKnowModal'
import dykJson from '../../../Content/dyk-modal.json';
import dykQuoteIcon from '../../images/icons/dyk-quote.png';
import wiseJourneyIcon from '../../images/icons/dyk-journey.png';
import wiseTipsIcon from '../../images/icons/dyk-wisetips.png';
import superHonestlyIcon from '../../images/icons/dyk-superhonestly.png';
import SmallScreenFilterModal from './SmallScreenFilterModal';
import BigScreenFilters from './BigScreenFilters';

const icons = [wiseJourneyIcon, wiseTipsIcon, superHonestlyIcon];
const dykCards = dykJson.cards.map((card, idx) => ({
    ...card,
    icon: icons[idx]
}))

dykJson.cards = dykCards;


const WiseTips = (props) => {
    const { wiseTips, filterOptions, location, user, fetchBookmarks, bookmarks } = props;
    const LIMIT = 6;
    const CATEGORGY_MAP = {
        'age_stages': 'ageStages',
        'domain': 'domains',
        'context': 'contexts'
    };
    const [type, setType] = useState('wiseTip');
    const [openCls, setOpenCls] = useState('w3-hide');
    const [filters, setFilters] = useState([]);
    const [searchTerm, setSearchTerm] = useState('');
    const [smFilterCls, setSmFilterCls] = useState('w3-hide');
    const [renderData, setRenderData] = useState([]);
    const [wtVisibleCount, setWTVisibleCount] = useState(LIMIT);
    const [cfVisibleCount, setCFVisibleCount] = useState(LIMIT);
    const [activeSearchTerm, setActiveSearchTerm] = useState(false);
    const[selectedTip,setSelectedTip] = useState(""); 

    useEffect(() => {
        setQueryFilters(location.search.replace('?', ''));
    }, []);

    useEffect(() => {
        setQueryFilters(location.search.replace('?', ''));
    }, [location]);

    useEffect(() => {
        getFilteredData();
    }, [filters]);

    const setTypeOnUrl = (type) => {
        const queryStr = querystring.parse(location.search.replace('?', ''));

        if (!queryStr['type'] || queryStr['type'] != type) {
            queryStr['type'] = type;
            navigate(`?${querystring.stringify(queryStr)}`, { replace: true });
        }
    }

    const handleFilterChange = (filters) => {
        let queryStr = {};
        filters.forEach(filter => {
            if (!queryStr[filter.filterId]) {
                queryStr[filter.filterId] = [];
            }
            queryStr[filter.filterId].push(filter.slug);
        });

        if (type) {
            queryStr['type'] = type;
        }

        if (searchTerm) {
            queryStr['search'] = searchTerm;
        }

        navigate(`?${querystring.stringify(queryStr)}`, { replace: true });
    }

    const setQueryFilters =  (data) => {
        const queryStr = querystring.parse(data.replace('?', ''));
        let filterSlugs = [];
        let srchTerm = '';

        for (let key in queryStr) {
            if (typeof queryStr[key] === 'string') {
                if (key == 'type') {
                    if ((queryStr[key] == 'coreFinding' || queryStr[key] == 'wiseTip') && type != queryStr[key]) {
                        setType(queryStr[key]);
                    }
                } else if (key == 'search' && queryStr[key]) {
                    srchTerm = queryStr[key]
                } else {
                    filterSlugs.push(queryStr[key]);
                }
            } else {
                filterSlugs = filterSlugs.concat(queryStr[key]);
            }
        }

        setSearchTerm(srchTerm);

        let filterList = [];
        filterOptions.forEach(filterOption => {
            let fList = filterOption.options.filter(i => filterSlugs.indexOf(i.slug) !== -1 );
            filterList = filterList.concat(fList);
        });

        setFilters(filterList);
    }

    const applyFilters = (filterArray, filterId, appliedFilters) => {
        const filterResult = [];
        filterArray.forEach(tip => {
            appliedFilters.forEach(item => {
                if (tip[CATEGORGY_MAP[filterId]] && tip[CATEGORGY_MAP[filterId]].nodes
                    && tip[CATEGORGY_MAP[filterId]].nodes.length) {
                    tip[CATEGORGY_MAP[filterId]].nodes.forEach(node => {
                        if (!item.options) {
                            if (node.slug === item.slug) {
                                if (!filterResult.find(fItem => fItem.slug === tip.slug)) {
                                    filterResult.push(tip);
                                }
                            }
                        }
                    });
                }
            })
        });
        return filterResult;
    }
     
    const shuffleFilterdData = (data) => {
        if (data){
          const shuffledData = data.sort( () => Math.random() - 0.5)
          return shuffledData  
        }
        return data
      }
    const getWiseTipByType = (data) => {
        const filteredData = data.filter(i => i.acf_wise_tip.type === type);
        const shuffledData = priorityDomainList(shuffleFilterdData(filteredData));
        return shuffledData;
    }

    const getFilteredData = () => {
        let filteredResult = getWiseTipByType(wiseTips).slice(0);

        if (type != 'coreFinding') {
            if (!filters.find(item => item.filterId === 'age_stages')) {
                filteredResult = filteredResult;
            } else if (filters.find(item => item.filterId === 'age_stages')) {
                filteredResult = applyFilters(filteredResult, 'age_stages', filters);
            }
        }

        if (filters.find(item => item.filterId === 'domain')) {
            filteredResult = applyFilters(filteredResult, 'domain', filters);
        }

        if (type != 'coreFinding') {
            if (filters.find(item => item.filterId === 'context')) {
                filteredResult = applyFilters(filteredResult, 'context', filters);
            }
        }

        if (searchTerm) {
            const searchResult = getSearchResult(filteredResult, searchTerm.toLowerCase());
            setRenderData(searchResult);
            setActiveSearchTerm(searchTerm);
        } else {
            setRenderData(filteredResult);
        }
    }

    const getSearchResult = (searchData, searchStr) => {
        return searchData.filter(wiseTip =>
        ((wiseTip.acf_wise_tip.tip && wiseTip.acf_wise_tip.tip.toLowerCase().includes(searchStr))
            || (wiseTip.acf_wise_tip.description && wiseTip.acf_wise_tip.description.toLowerCase().includes(searchStr))
            || wiseTip.title.toLowerCase().includes(searchStr))
        );
    }

    const handleOnChange = (e) => {
        setSearchTerm(e.target.value);
        if (!e.target.value) {
            clearSearch();
        }
    }

    const handleSearch = (e) => {
        if (e.key === 'Enter' || e.keyCode === 13) {
            if (searchTerm) {
                const queryStr = querystring.parse(location.search.replace('?', ''));
                queryStr['search'] = searchTerm;
                navigate(`?${querystring.stringify(queryStr)}`, { replace: true });
            }
        }
    }

    const clearSearch = () => {
        const queryStr = querystring.parse(location.search.replace('?', ''));
        if (queryStr['search']) {
            delete queryStr['search'];
            navigate(`?${querystring.stringify(queryStr)}`, { replace: true });
        }
        setActiveSearchTerm(null);
    }

    const priorityDomainList = data =>  data.reduce((domainList,item) => {
        let parentnode = item.domains.nodes.find(x => !x.parentDatabaseId)
        if (domainList.find(x => x.domains.nodes.find(y => !y.parentDatabaseId).name === parentnode.name  ) ) {
          domainList = [...domainList, item]
        }
        else {
          domainList = [item, ...domainList]
        }
        return domainList;
      },[])

    const handleLoadMore = () => {
        let intialData = [];
        let remainingData = renderData;
        let count = LIMIT;
        if (type === 'coreFinding') {
            setCFVisibleCount(prevState => (prevState + LIMIT));
            count = cfVisibleCount;
        }
       else {
        setWTVisibleCount(prevState => (prevState + LIMIT));
        count = wtVisibleCount;
       }   
       intialData = renderData.slice(0,count)
       remainingData = renderData.slice(count,renderData.length + 1)  
       const shuffledData = priorityDomainList(remainingData)
       setRenderData([...intialData,...shuffledData])
    }

    const handleClearFilter = () => {
        handleFilterChange([]);
    }

    const handleRemoveFilter = (removeItem) => {
        const pos = filters.findIndex(item => item.slug == removeItem.slug);
        const [elem] = filters.splice(pos, 1);
        let nFilter = filters.filter(item => item.parentId !== elem.id);
        if (elem.parentId && elem.filterId === 'domain') {
            const parentPos = filters.findIndex(item => item.id === elem.parentId);
            if (parentPos > 0) {
                const [removeMain] = filters.splice(parentPos, 1);
                nFilter = filters.filter(item => item.id !== removeMain.id);
            }
        }
        handleFilterChange(nFilter);
    }

    const renderWiseTips = (renderCards) => {
        let count = wtVisibleCount;
        if (type === 'coreFinding') {
            count = cfVisibleCount;
        }
        return renderCards.slice(0, count).map((cardData, idx) => (
            <WiseTipCard key={cardData.slug}
                data={cardData}
                share = {idx === selectedTip}
                idx={idx}
                setSelectedTip={(idx) => setSelectedTip(idx)}
                location={location}
                user={user}
                bookmarks={bookmarks}
                fetchBookmarks={fetchBookmarks}
            />
        ));
    }

    let visibleCount = wtVisibleCount;
    if (type === 'coreFinding') {
        visibleCount = cfVisibleCount;
    }

    return (
        <>
            <div className="w3-row pw-wt-list-container">
                <div className="w3-row">
                    <div className="pw-wt-main-title">
                        SEARCH <br className="w3-hide-medium w3-hide-large" />{`${(type === 'coreFinding') ? 'CORE FINDINGS' : 'WISETIPS'}`}
                    </div>
                </div>
                <div className="w3-content">
                    <div className="w3-row pw-wt-top-section">
                        <div className="w3-col s12 m4">
                            <div className="pw-wt-search-box">
                                <div className="pw-wt-search-icon" />
                                <input className="w3-input pw-wt-input"
                                    type="text"
                                    name="search"
                                    placeholder="Search"
                                    value={searchTerm}
                                    onChange={handleOnChange}
                                    onKeyUp={handleSearch}
                                />
                                {searchTerm && <i className="pw-wt-close-icon" onClick={clearSearch} />}
                            </div>
                        </div>
                        <div className="w3-col m8">
                            <div className="w3-row w3-hide-small pw-wt-filter-tag-section">
                                {(filters.length > 0) && <>
                                    {filters.filter(fItem => !fItem.options).map(item => {
                                        if (type === 'coreFinding' && item.filterId !== 'domain') {
                                            return null;
                                        }
                                        return (<div key={item.slug} className="pw-wt-filter-tag">
                                            {item.name}
                                            <img src={closeIcon} className="pw-wt-ft-close" onClick={() => handleRemoveFilter(item)} />
                                        </div>)
                                    })}

                                    {(type === 'coreFinding') ?
                                        (filters.filter(i => i.filterId === 'domain').length) ?
                                            <div className="pw-wt-filter-clear" onClick={handleClearFilter}>
                                                Clear all
                                            </div>
                                            : null
                                        :
                                        <div className="pw-wt-filter-clear" onClick={handleClearFilter}>
                                            Clear all
                                        </div>
                                    }
                                </>}
                            </div>
                            {activeSearchTerm && <div className="w3-row pw-wt-search-result">
                                {`Search Results: ${activeSearchTerm}`}
                            </div>}
                        </div>
                    </div>
                    <div className="w3-row">
                        <div className="w3-col m4">
                            <div className="w3-container">
                                <div className="pw-wt-tabs">
                                    <button
                                        className={(type === 'wiseTip') ? 'active' : ''}
                                        onClick={() => setTypeOnUrl('wiseTip')}
                                    >
                                        WiseTips
                                    </button>
                                    <button
                                        className={(type === 'coreFinding') ? 'active' : ''}
                                        onClick={() => setTypeOnUrl('coreFinding')}
                                    >
                                        Core Findings
                                    </button>
                                </div>
                            </div>
                            <div className="pw-wt-content-left">
                                <div className="pw-wt-filter-section">
                                    <div className="pw-wt-filter-title w3-hide-medium w3-hide-large"
                                        onClick={() => setSmFilterCls('w3-show')}>
                                        Filters
                                    </div>
                                    <div className="w3-hide-small">
                                        <BigScreenFilters
                                            filterOptions={(type === 'coreFinding') ? filterOptions.filter(i => i.slug === 'domain') : filterOptions}
                                            filters={filters}
                                            setFilters={handleFilterChange}
                                        />
                                    </div>

                                </div>
                            </div>
                        </div>

                        <div className="w3-col m8">

                            <div className="pw-wt-content-right">
                                <div className="w3-row pw-wt-cards">
                                    {(renderData && !renderData.length) ?
                                        (filters.length && !activeSearchTerm) ?
                                            <div className="w3-col w3-center w3-padding w3-padding-64">{`${(type === 'coreFinding') ? 'Core Findings' : 'Tips'} for this domain are coming soon - stay tuned!`}</div> :
                                            (activeSearchTerm) ?
                                                <div className="w3-col w3-center w3-padding w3-padding-64">{`Our ${(type === 'coreFinding') ? 'Core Findings' : 'WiseTips'} search engine is still being improved to bring the best ${(type === 'coreFinding') ? 'core findings' : 'tips'} to you.`}<br />Try our filter function or another keyword!</div>
                                                :
                                                <div className="w3-col w3-center w3-padding w3-padding-64">{`${(type === 'coreFinding') ? 'Core Findings' : 'Tips'} for this domain are coming soon - stay tuned!`}</div>
                                        :
                                        renderWiseTips(renderData)
                                    }
                                </div>
                                <div className="w3-row w3-margin-top w3-margin-bottom w3-display-container w3-center">
                                    {visibleCount < renderData.length && <button className="pw-wt-load-more" onClick={handleLoadMore}>
                                        LOAD MORE
                                    </button>}
                                    <img loading="lazy" src={dykQuoteIcon}
                                        className="pw-wt-quote-img w3-hide"
                                        onClick={() => setOpenCls('w3-show')}
                                    />

                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <DidYouKnowModal
                openCls={openCls}
                setOpenCls={setOpenCls}
                data={dykJson}
            />
            <SmallScreenFilterModal
                smFilterCls={smFilterCls}
                setSmFilterCls={setSmFilterCls}
                filterOptions={(type === 'coreFinding') ? filterOptions.filter(i => i.slug === 'domain') : filterOptions}
                filters={filters}
                setFilters={handleFilterChange}
                clearFilter={handleClearFilter}
                type={type}
            />
        </>
    )
}
export default WiseTips;