import React, { useState, useEffect, useContext } from 'react';
import WiseTips from '../../components/WiseTips';
import '../../components/WiseTips/wise-tips.scss'
import Layout from '../../components/layout';
import SEO from "../../components/seo";
import { api } from "../../../utils";
import UserContext from '../../context/UserContext';

const SmQuotesGroupIcon = 'https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/sm-quotes-group.png';
const QuotesGroupIcon = 'https://cgs-cdn.sgp1.cdn.digitaloceanspaces.com/parent-wise-prod/website-assets/bg/top-right-pattern.png';

const WiseTipIndex = ({ location, pageContext: { wiseTips, ageStages, domains, contexts } }) => {
    const userData = useContext(UserContext);
    const user = userData && userData.user;
    const [bookmarks, setBookmarks] = useState([]);

    const getList = (data,filterId) => {
      if (!data || !data.nodes || !data.nodes.length) {
        return null;
      }
      const list = [];
      data.nodes.forEach(item => {
        list.push(Object.assign(item,{filterId} ));
      });

      return list;
    }

    useEffect(() => {
      if (user) {
        fetchBookmarks();
      }
    }, [user]);

    const fetchBookmarks = () => {
      api.get('/api/v1/user-bookmark/list/wise-tip')
      .then(resp => {
        if (resp && resp.success && resp.data && resp.data.length) {
          let bookmarks = [];
          resp.data.forEach(i => bookmarks.push(i.slug));
          setBookmarks(bookmarks);
        } else {
          setBookmarks([]);
        }
      });
    }

 
    const ageStagesList = getList(ageStages,'age_stages');
    const contextsList = getList(contexts,'context');
    const domainsList = getList(domains,'domain'); 

    const filterOptions = [
      {
        name: 'Ages & Stages',
        slug: 'age_stages',
        options: ageStagesList,
      },
      {
        name: 'Domain',
        slug: 'domain',
        options: domainsList,
      },
      {
        name: 'Context',
        slug: 'context',
        options: contextsList,
      },
    ];

    return <div className="pw-wt-page">
        <div className="w3-display-container pw-wt-texture">
            <img loading="lazy" src={QuotesGroupIcon} className="pw-wt-top-right-texture w3-hide-small w3-hide-medium" />
            <img loading="lazy" src={SmQuotesGroupIcon} className="pw-wt-top-right-sm-texture w3-hide-medium w3-hide-large" />
            <Layout>
                <SEO
                  title="WiseTips"
                  path={location.pathname}
                />
                <WiseTips
                  wiseTips={wiseTips}
                  filterOptions={filterOptions}
                  location={location}
                  user={user}
                  bookmarks={bookmarks}
                  fetchBookmarks={fetchBookmarks}
                />
            </Layout>
        </div>
    </div>
}

export default WiseTipIndex