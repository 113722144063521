import React from "react";
import "./radio-btn.scss";

const RadioButton = (props) => {
    const { checked, onChange, label, subLabel, value, labelCls, circleCls, circleCheckedCls } = props;
    return (
        <div
            className="pw-radio-btn-container"
            onClick={() => {
                onChange(value);
            }}
        >
            <div
                className={`${circleCls ? circleCls : "pw-radio-circle"}`}
            >
                {checked && <div
                    className={`${circleCheckedCls ? circleCheckedCls : "pw-radio-check-circle"}`}
                />}
            </div>
            <div className="pw-radio-text-section">
                <div className={`${labelCls ? labelCls : "pw-radio-label"}`}>{label}</div>
                <div className="pw-radio-sub-label">{subLabel}</div>
            </div>
        </div>
    );
}


export default RadioButton;