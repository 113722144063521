import React from 'react';

const SmallScreenFilters = (props) => {
    const { filterOptions, setSubOptionData, filters } = props;

    const handleArrowClick = (data,header,subHeader) => {  
        setSubOptionData(Object.assign(data,{
            header,
            subHeader
        }));
    };

    const SubCatListOption = ({ data, filters }) => {
        const selectedOptions = filters.filter(fItem => data.options.find(oItem=> oItem.parentId === fItem.parentId));
        return (<li>
            <div className="pw-wt-sm-filter-option" onClick={() => handleArrowClick(data,'Domain',data.name)}>
                <div className="pw-wt-sm-fo-label-section">
                    <div className="pw-wt-sm-fo-label">
                        {data.name}
                    </div>
                    {<div className="pw-wt-sm-filter-selected-text pw-wt-sm-filter-selected-sub-text">
                        {selectedOptions.filter(item => item.parentId !== null).map(mItem=>mItem.name).join(', ')}
                    </div>}
                </div> 
                <div className="pw-wt-sm-fo-right-chevron" />
            </div>
        </li>
        );

    }

    const SubCategory = ({ data, filters }) => {
        return (
            <ul className="pw-wt-sm-filters">
                {data.options.filter(item => item.parentId == null).map((option, index) => {
                    const optionName = `filter-sub-cat-option-${option.slug}-${index}`;
                    option.options = data.options.filter(optionItem=> optionItem.parentId === option.id);
                    return (
                        <SubCatListOption
                            data={option}
                            hasOptions={option.options}
                            optionName={optionName}
                            key={optionName}
                            optionIndex={index}
                            filters={filters}
                        />
                    );
                })}
            </ul>
        );
    };

    const ListOption = ({ filter, filters }) => { 
        const selectedOptions = filters.filter(fItem => filter.options.find(oItem=> oItem.filterId === fItem.filterId));
        return (<li className="pw-wt-sm-filter-top-border">
            <div className="pw-wt-sm-filter-option" onClick={e => filter.slug != 'domain' ? handleArrowClick(filter,filter.name) : ()=>{}  }>
                <div className="pw-wt-sm-fo-label-section">
                    <div className="pw-wt-sm-fo-label pw-wt-sm-fo-main-label">
                        {filter.name}
                    </div>
                    {filter.slug !== 'domain' &&<div className="pw-wt-sm-filter-selected-text">
                        {selectedOptions.map(mItem=>mItem.name).join(', ')}
                    </div>}
                </div>
                {(filter.slug != 'domain') && (
                    <div  className="pw-wt-sm-fo-right-chevron" />
                )}
            </div>
            {filter.slug == 'domain' &&  <SubCategory data={filter} filters={filters}   />}
        </li>
        );
    }

    return (
        <ul className="pw-wt-sm-filters">
            {filterOptions.map((filterOptionItem, index) => {
                const optionName = `filter-option-${index}`;
                return (
                    <ListOption
                        filters={filters}
                        filter={filterOptionItem} 
                        optionName={optionName}
                        key={optionName} 
                    />
                );
            })}
        </ul>
    );
};

export default SmallScreenFilters;